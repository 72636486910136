<template>
  <div :data-aos="animation" data-aos-once="true" class="relative z-10 flex flex-col" :class="width">
    <v-text
        v-if="subTitle && optKeySubtitle"
        tag="h3"
        class="pt-0 text-bw-09/50 text-sm leading-6 font-semibold uppercase mb-4"
        :class="light ? '!text-white/50' : ''"
        :default_value="subTitle"
        :option_key="optKeySubtitle"
    />

    <template v-if="optKeyTitle">
      <div
          v-if="title"
          class="font-[550] text-bw-03 text-2xl lg:text-3xl leading-4xl lg:leading-44px"
          :class="[light ? '!text-white' : '', sizeTitle]"
      >
        <v-content :default_value="title" :option_key="optKeyTitle" :type="typeTitle"/>
      </div>
      <div
          v-else
          class="font-[550] text-bw-03 text-2xl lg:text-3xl leading-4xl lg:leading-44px"
          :class="[light ? '!text-white' : '', sizeTitle]"
      >
        <v-content :option_key="optKeyTitle" :type="typeTitle"/>
      </div>
    </template>

    <template v-if="!optKeyTitle && !optKeyContent">
      <h2
          v-if="title"
          class="font-[550] text-bw-03 text-2xl lg:text-3xl leading-4xl lg:leading-44px"
          :class="[light ? '!text-white' : '', sizeTitle]"
      >
        {{ title }}
      </h2>
    </template>

    <template v-if="optKeyContent">
      <div
          v-if="title && description"
          class="font-[550] text-bw-03 text-2xl lg:text-3xl leading-4xl lg:leading-44px"
          :class="[light ? '!text-white' : '', sizeTitle]"
      >
        <v-content
            :default_value="`${title} <p class=' text-base leading-6 font-normal text-black/60'>${description}</p>`"
            :option_key="optKeyContent"
            :type="typeContent"
        />
      </div>

      <div
          v-else
          class="font-[550] text-bw-03 text-2xl lg:text-3xl leading-4xl lg:leading-44px"
          :class="[light ? '!text-white' : '', sizeTitle]"
      >
        <v-content :option_key="optKeyContent" :type="typeContent"/>
      </div>
    </template>

    <nuxt-link
        v-show="textLink"
        class="mt-4 hover:text-accent-01 uppercase text-sm leading-5 font-medium flex text-primary duration-400 font-semibold"
        :to="url"
    >
      <span>{{ textLink }}</span>
      <span class="w-4.5 h-4.5 i-custom-chevron-right"></span>
    </nuxt-link>
  </div>
</template>

<script setup lang="ts">
defineProps({
  title: {
    type: String
  },
  subTitle: {
    type: String
  },
  width: {
    type: String
  },
  url: {
    type: String
  },
  textLink: {
    type: String
  },
  animation: {
    type: String
  },
  light: {
    type: Boolean
  },
  description: {
    type: String
  },
  sizeTitle: {
    type: String
  },
  optKeyTitle: {
    type: String
  },
  optKeyContent: {
    type: String
  },
  optKeySubtitle: {
    type: String
  }
})

const typeTitle = ref('h1 h2 h3 h4 h5 h6  bold italic underline forecolor')
const typeContent = ref('h1 h2 h3 h4 h5 h6 bold italic underline align numlist bullist forecolor')
</script>

<style></style>
